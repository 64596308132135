<template>
  <v-row>
    <v-col cols="12" class="text-center">
      <div
        class="contact_card"
        v-bind:class="$vuetify.breakpoint.smAndDown ? '' : ''"
      >
        <p
          class="title_contact_card"
          v-bind:class="$vuetify.breakpoint.smAndDown ? '' : ''"
          v-html="title"
        ></p>
        <!-- <p class="question_mark">?</p> -->
        <p class="click_the_button">Clicca il pulsante qui sotto per contattarci</p>

        <v-card
          color="white"
          :to="{ name: 'Contacts', params: { topic: contact_type } }"
        >
          <v-card-text
            ><span class="text_contact_button">Contattaci</span>
          </v-card-text>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    contact_type: {
      type: String,
    },
  },
  components: {},
  data: () => ({}),
  methods: {},
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped>
.contact_card {
  padding: 1rem;
  background: rgb(0, 177, 242);
  border-radius: 10px;
  box-shadow: 2px 2px 5px black;;
  /* max-width: 80%; */
}

.title_contact_card {
  color: white;
  font-size: 1.5rem;
  line-height: 1;
  font-weight: bold;
  z-index: 10;
}
/* .question_mark {
  font-size: 2.2rem;
  font-weight: bold;
  color:white;
} */

.click_the_button {
  font-weight: bold;
  color:white;
  margin-top:2rem;
}

.text_contact_button {
  font-size: 1.2rem;
  font-weight: bold;
  color: black;
}
</style>