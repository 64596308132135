<template>
  <div
    class="home"
    v-bind:class="$vuetify.breakpoint.smAndDown ? 'mx-2' : 'mx-0 px-0'"
    style="margin-bottom: 50px; margin-top: 80px"
  >
    <v-row no-gutters justify="center" class="mt-12 black--text">
      <v-col v-bind="main_card_width">
        <v-card color="transparent" class="pa-2">
          <v-card-text>
            <v-row class="rounded_row_white black--text pa-2 py-4 pb-12">
              <v-col cols="12" sm="4" v-if="!$vuetify.breakpoint.smAndDown">
                <v-img
                  class="service_image_position"
                  :src="require('@/assets/imgs/pages/backup2.png')"
                  alt="Icona backup"
                  contain
                  max-width="200"
                  :max-height="$vuetify.breakpoint.smAndDown ? 100 : ''"
                ></v-img>
              </v-col>
              <v-col cols="12" sm="7">
                <h1 class="service_title">I tuoi dati sono al sicuro?</h1>
                <div class="small_screen_service_image">
                  <v-img
                    v-if="$vuetify.breakpoint.smAndDown"
                    :src="require('@/assets/imgs/pages/backup2.png')"
                    alt="Icona backup"
                    class="mt-2 mb-6"
                    contain
                    max-width="200"
                    :max-height="$vuetify.breakpoint.smAndDown ? 100 : ''"
                  ></v-img>
                </div>
                <h2 class="service_subtitle">
                  Tieni i tuoi dati al riparo dai guai!
                </h2>
                <p>
                  Eseguire un backup vuol dire creare e archiviare in un luogo
                  sicuro una o più copie dei propri dati.
                </p>

                <p>
                  Il backup dei dati è una pratica informatica che purtroppo
                  troppo spesso viene sottovalutata della cui importanza ci si
                  rende conto quando ormai è troppo tardi.
                </p>

                <p>
                  Negli ultimi anni sempre più spesso si sente parlare di
                  attacchi informatici di tipo Ransomware, virus informatici che
                  hanno la caratteristica di criptare e rendere inaccessibili i
                  dati se non a fronte del pagamento di un riscatto.
                </p>
                <p>
                  Gli effetti dei Ramsonware sono devastanti sulle aziende
                  quando queste non sono in grado di ripristinare in autonomia i
                  propri dati e quindi le proprie attività.
                </p>

                <p>
                  Storicizza i tuoi dati attraverso piani di data retention e
                  previeni la perdita attraverso un efficace piano di data
                  protection.
                </p>
                <!-- <v-btn
                  :to="{ name: 'Contacts', params: { topic: 'backup' } }"
                  outlined
                  block
                >
                  Contattaci
                </v-btn> -->
              </v-col>
              <v-col cols="12" class="text-center">
                <contactCardButton
                  contact_type="backup"
                  title="Vuoi proteggere i tuoi dati da perdite e danneggiamenti?"
                />
              </v-col>

              <!-- <v-col cols="12" class="text-center">
                <v-card
                  small
                  text
                  color="white black--text"
                  :to="{ name: 'OurServicesAssistenzaConsulenza' }"
                  flat
                >
                  <v-card-actions>
                    <v-spacer />
                    Torna all'elenco dei servizi
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-col> -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import contactCardButton from "@/components/contactCardButton";

export default {
  components: {
    contactCardButton,
  },
  metaInfo: {
    title: "Soluzioni di Backup",
    meta: [
      {
        name: "description",
        content:
          "Quanto sono importanti i tuoi dati? Ti aiutiamo ad implementare strategie per tenere al sicuro i tuoi dati da rischi di perdite e manomissioni.",
      },
      {
        name: "robots",
        content: "index",
      },
    ],
  },
};
</script>